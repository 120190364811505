
@import "css-rules.scss";
@import "../../_variables.scss";

@function calc-scale($value, $min, $scale){

    @if ($value >= $min) {
        $result: ceil($value * $scale);
        @if $result > $min {
            @return $result + 0px
        }
        @else {
            @return $min + 0px
        }
    }
    @else {
        @return $value + 0px
    }

}

@function strip-unit($number) {
  @return $number / ($number * 0 + 1);
}

@mixin pux-scale($key, $value, $min) {
  #{$key}: calc-scale($value, $min, $scale-xxs);
  @include media(xs){
    #{$key}: calc-scale($value, $min, $scale-xs);
  }
  @include media(sm){
    #{$key}: calc-scale($value, $min, $scale-sm);
  }
  @include media(md){
    #{$key}: calc-scale($value, $min, $scale-md);
  }
  @include media(lg){
    #{$key}: calc-scale($value, $min, $scale-lg);
  }
  @include media(xl){
    #{$key}: calc-scale($value, $min, $scale-xl);
  }
  @include media(xxl){
    #{$key}: calc-scale($value, $min, $scale-xxl);
  }
}

@mixin pux-scale-font($value, $min: $min-font-size, $line-height: 0, $min-line-height:($min-font-size+4)) {
  //@include font-size(calc-scale($value, $min, $scale-xxs), calc-scale($line-height, $min-line-height, $scale-xxs));
  font-size: calc-scale($value, $min, $scale-xxs);
  line-height: calc-scale($line-height, $min-line-height, $scale-xxs);
  @include media(xs){
      font-size: calc-scale($value, $min, $scale-xs);
      line-height: calc-scale($line-height, $min-line-height, $scale-xs);
    //  @include font-size(calc-scale($value, $min, $scale-xs), calc-scale($line-height, $min-line-height, $scale-xs));
  }
  @include media(sm){
    font-size: calc-scale($value, $min, $scale-sm);
    line-height: calc-scale($line-height, $min-line-height, $scale-sm);
      //@include font-size(calc-scale($value, $min, $scale-sm), calc-scale($line-height, $min-line-height, $scale-sm));
  }
  @include media(md){
    font-size: calc-scale($value, $min, $scale-md);
    line-height: calc-scale($line-height, $min-line-height, $scale-md);
      //@include font-size(calc-scale($value, $min, $scale-md), calc-scale($line-height, $min-line-height, $scale-md));
  }
  @include media(lg){
    font-size: calc-scale($value, $min, $scale-lg);
    line-height: calc-scale($line-height, $min-line-height, $scale-lg);
    //@include font-size(calc-scale($value, $min, $scale-lg), calc-scale($line-height, $min-line-height, $scale-lg));
  }
  @include media(xl){
    font-size: calc-scale($value, $min, $scale-xl);
    line-height: calc-scale($line-height, $min-line-height, $scale-xl);
     //@include font-size(calc-scale($value, $min, $scale-xl), calc-scale($line-height, $min-line-height, $scale-xl));
  }
  @include media(xxl){
    font-size: calc-scale($value, $min, $scale-xxl);
    line-height: calc-scale($line-height, $min-line-height, $scale-xxl);
    //  @include font-size(calc-scale($value, $min, $scale-xxl), calc-scale($line-height, $min-line-height, $scale-xxl));
  }
}

@mixin pux-scale-padding($top, $right, $bottom, $left, $min: $min-padding){
    padding: calc-scale($top, $min, $scale-xxs) calc-scale($right, $min, $scale-xxs) calc-scale($bottom, $min, $scale-xxs) calc-scale($left, $min, $scale-xxs);
    @include media(xs){
        padding: calc-scale($top, $min, $scale-xs) calc-scale($right, $min, $scale-xs) calc-scale($bottom, $min, $scale-xs) calc-scale($left, $min, $scale-xs);
    }
    @include media(sm){
        padding: calc-scale($top, $min, $scale-sm) calc-scale($right, $min, $scale-sm) calc-scale($bottom, $min, $scale-sm) calc-scale($left, $min, $scale-sm);
    }
    @include media(md){
        padding: calc-scale($top, $min, $scale-md) calc-scale($right, $min, $scale-md) calc-scale($bottom, $min, $scale-md) calc-scale($left, $min, $scale-md);
    }
    @include media(lg){
        padding: calc-scale($top, $min, $scale-lg) calc-scale($right, $min, $scale-lg) calc-scale($bottom, $min, $scale-lg) calc-scale($left, $min, $scale-lg);
    }
    @include media(xl){
        padding: calc-scale($top, $min, $scale-xl) calc-scale($right, $min, $scale-xl) calc-scale($bottom, $min, $scale-xl) calc-scale($left, $min, $scale-xl);
    }
    @include media(xxl){
        padding: calc-scale($top, $min, $scale-xxl) calc-scale($right, $min, $scale-xxl) calc-scale($bottom, $min, $scale-xxl) calc-scale($left, $min, $scale-xxl);
    }
}

@mixin pux-scale-margin($top, $right, $bottom, $left, $min: $min-padding){
    margin: calc-scale($top, $min, $scale-xxs) calc-scale($right, $min, $scale-xxs) calc-scale($bottom, $min, $scale-xxs) calc-scale($left, $min, $scale-xxs);
    @include media(xs){
        margin: calc-scale($top, $min, $scale-xs) calc-scale($right, $min, $scale-xs) calc-scale($bottom, $min, $scale-xs) calc-scale($left, $min, $scale-xs);
    }
    @include media(sm){
        margin: calc-scale($top, $min, $scale-sm) calc-scale($right, $min, $scale-sm) calc-scale($bottom, $min, $scale-sm) calc-scale($left, $min, $scale-sm);
    }
    @include media(md){
        margin: calc-scale($top, $min, $scale-md) calc-scale($right, $min, $scale-md) calc-scale($bottom, $min, $scale-md) calc-scale($left, $min, $scale-md);
    }
    @include media(lg){
        margin: calc-scale($top, $min, $scale-lg) calc-scale($right, $min, $scale-lg) calc-scale($bottom, $min, $scale-lg) calc-scale($left, $min, $scale-lg);
    }
    @include media(xl){
        margin: calc-scale($top, $min, $scale-xl) calc-scale($right, $min, $scale-xl) calc-scale($bottom, $min, $scale-xl) calc-scale($left, $min, $scale-xl);
    }
    @include media(xxl){
        margin: calc-scale($top, $min, $scale-xxl) calc-scale($right, $min, $scale-xxl) calc-scale($bottom, $min, $scale-xxl) calc-scale($left, $min, $scale-xxl);
    }
}
