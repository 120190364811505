
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@import "selectric.scss";

h1,
.h1{
    @include pux-scale-font(38px, 25px, 45px, 32px);
    font-weight:bold;
}

h2,
.h2{
    @include pux-scale-font(28px, 20px, 40px, 30px);
    font-weight:bold;
}

h3,
.h3{
    @include pux-scale-font(20px, 18px, 30px, 22px);
    font-weight:bold;
    color:#0d3264;
}

h4,
.h4{
    @include pux-scale-font(16px, 16px, 30px, 20px);
    font-weight:bold;
    color:#0d3264;
}

big,
.perex{
    font-size: $font-size-perex;
    line-height: $line-height-perex;
}

small,
.text-small{
  font-size: $font-size-small;
  line-height: $line-height-small;
}


.main{
  ol{
      margin:0;
      padding:0;
      list-style:none;
      counter-reset: my-ol;
      li{
          position:relative;
          margin:0 0 15px 0;
          padding:0 0 0 20px;
          font-size: 15px;
          @include media(lg){
            padding:0 0 0 40px;
          }
          &:before{
              content: counter(my-ol);
              counter-increment: my-ol;
              color:$ol-number;
              position:absolute;
              left:0;
              top:0px;
              font-weight:bold;
          }
      }
  }
  ul{
      margin:0;
      padding:0;
      list-style:none;
      li{
          font-size: 15px;
          margin:0 0 15px 0;
          padding:0 0 0 20px;
          position:relative;
          @include media(lg){
            padding:0 0 0 40px;
          }
          &:before{
              content:"";
              width:10px;
              height:2px;
              background:$ul-bullet;
              position:absolute;
              top:8px;
              left:0;
              @include media(lg){
                width:20px;
              }
          }
      }
  }
}

a{
  cursor: pointer;
}

.btn-cross{
  padding:0 0 0 20px;
  position: relative;
  &:before{
    content:"\00d7";
    font-size: 25px;
    font-weight: 400;
    position:absolute;
    left:0;
    line-height: 25px;
  }
  &:focus,
  &:hover{
    &:before{
      color:darken(red,10);
    }
  }
}

.btn{
  display: inline-block;
  @include border-radius(5px);
  border: none;
  padding:10px 15px;
  @include box-shadow(none);
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
}

.btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $second-color;
      color: $btn-primary-color;
  }
  &.active{
    text-decoration: none;
    color: $second-color;
    background: $btn-primary-color;
  }
}

label{
  display: block;
  margin:10px 0 5px 0;
  font-size:13px;
  font-weight: bold;
}

.form-control,
textarea,
input[type="text"],
input[type="password"]{
  width:100%;
  height: $input-height;
  padding: 0 15px;
  border: 1px solid $border-color;
  background: $input-background;
  @include border-radius(5px);
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    outline: 0;
    border: 1px solid $focus-border-color;
  }
  &:disabled{
    background: #d2dbe4;
    border-color: #d2dbe4;
  }
}

textarea{
  height: $textarea-height;
  padding: 15px;
    &:focus{
      border: 1px solid $focus-border-color;
    }
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 30px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:20px;
                height:20px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:0px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:5px;
                width:10px;
                height:10px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 30px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:20px;
                height:20px;
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:0px;
                border:1px solid $checkbox-border;
                @include transition;
                @include border-radius(3px);
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:5px;
                width:10px;
                height:10px;
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
                @include border-radius(2px);
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.respo-table{
  table{
    width:100%;
    font-size: 13px;
    a{
      font-weight: 400;
    }
    thead{
      display: none;
      @include media(md){
        display: table-header-group;
      }
      tr{
        border-bottom: 1px solid $table-tr-border;
        th{
          font-weight: bold;
          color:#fff;
          border: 1px solid $table-tr-border;
          a{
            padding:15px 10px;
            color:#fff;
            display:block;
            background: $second-color;
            &:focus,
            &:hover{
              text-decoration: none;
              background: darken($second-color,2);
            }
            &.order{
              position: relative;
              &:after{
                content: "";
                @include triangle-bottom(4px, 4px, 7px, #fff);
                vertical-align: middle;
                display: inline-block;
                margin:0 0 0 5px;
              }
            }
            &.order-up{
              position: relative;
              &:after{
                content: "";
                @include triangle-top(4px, 4px, 7px, #fff);
                vertical-align: middle;
                display: inline-block;
                margin:0 0 0 5px;
              }
            }
          }
          &:first-child{
            @include border-radius-all(5px, 0, 5px, 0);
            a{
              @include border-radius-all(5px, 0, 5px, 0);
            }
          }
          &:last-child{
            @include border-radius-all(0, 5px, 0, 5px);
            a{
              @include border-radius-all(0, 5px, 0, 5px);
            }
          }
        }
      }
    }
    tbody{
      tr{
        display: block;
        border: 1px solid $table-tr-border;
        border-bottom: none;
        margin:0 0 20px 0;
        &.tr-even{
          background: $table-even-background;
        }
        @include media(md){
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid $table-tr-border;
        }
        &.tr-detail{
          display: none;
          border:none;
          td{
              padding:20px;
              border-top:2px solid $color-line;
              border-bottom:2px solid $color-line;
          }
          &.open{
            display: table-row;
          }
        }
        td{
          @include flex-block;
          @include justify-content(space-between);
          border: 1px solid $table-tr-border;
          width:100%;
          padding:15px 10px;
          //border-bottom: 1px solid $table-tr-border;
          @include media(md){
            display: table-cell;
            width:auto;
          }
          &:before{
              content:attr(data-th);
              display:block;
              text-align:left;
              font-family:$font-family !important;
              margin:0 15px 0 0;
              @include media(md){
                  display:none;
              }
          }
          &:first-child{
            @include border-radius-all(5px, 0, 5px, 0);
          }
          &:last-child{
            @include border-radius-all(0, 5px, 0, 5px);
          }
        }
      }
    }
  }
}

.line{
  background: #d2dbe4;
  width:100%;
  height:1px;
  margin:10px 0;
  @include media(lg){
    margin:20px 0;
  }
}

.border-box{
  margin:15px 0;
  padding:15px;
  border:1px solid $second-color;
  @include border-radius(5px);
  &-btn{
    @include flex-block;
    @include align-items(center);
    @include flex-wrap(wrap);
    @include media(sm){
      @include flex-wrap(nowrap);
    }
    .btn{
      width:100%;
      margin:0 0 15px 0;
      @include media(sm){
        width:auto;
        margin:0 15px 0 0;
      }
      &:last-child{
        margin:0;
      }
    }
  }
  &.inactive{
    position: relative;
    &:before{
      content:"";
      top:-1px;
      left:-1px;
      right: -1px;
      bottom: -1px;
      position: absolute;
      z-index: 22;
      background: rgba(231,238,243,0.8);
    }
  }
}

.title-box{
  @include flex-block;
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(space-between);
  @include media(sm){
    @include flex-direction(row);
  }
  &-title{

  }
}

.table-line{
  table{
    font-size: 13px;
    width:100%;
    tr{
      border-bottom:1px solid $color-line;
      td{
        padding:10px 10px;
        min-width: 140px;
        @include media(xl){
          padding:15px 20px;
        };
        &:first-child{
          font-weight: bold;
        }
      }
    }
  }
}

.form-item{
  @include flex-block;
  @include align-items(center);
  padding:0 0 10px 0;
  &-label{
    width:100px;
    min-width: 100px;
    label{
      margin:0;
    }
  }
  &-input{
    width:100%;
  }
}

.required{
  &:after{
    content:"*";
    color: $main-color;
    margin:0 0 0 5px;
  }
}

.btn-box{
  @include flex-block;
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(space-between);
  @include media(sm){
    @include flex-direction(row);
  }
}

.GridPager{
  a,
  span{
    width: 30px;
    height:30px;
    @include border-radius(5px);
    margin:0 10px 0 0;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
  }
  span{
    background: #fff;
    color:$second-color;
  }
  a{
    background: $main-color;
    color:#fff;
    &:focus,
    &:hover{
      background: darken($main-color,5);
    }
  }
}

.relative{
  position: relative;
}

.datepick{
  position: relative;
  input{
    position: relative;
  }
  &:after{
    content:"";
    width: 18px;
    height:18px;
    background: url('../img/calendar.svg');
    position: absolute;
    z-index: 22;
    right:10px;
    top:11px;
    line-height: 1;
    background-size: 18px 18px;
  }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 4px 0;
    margin: 1px 0 0;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}
